import React, { Component, Fragment } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';

import { CPStore } from 'apps/CPApp';
import InvoicePreviewCard from 'components/Core/Invoice/Preview/Card/InvoicePreviewCard';
import UnpayableCard from 'components/Core/NonPayEnabled/UnpayableCard/UnpayableCard';
import OpenInvoicesNotice from 'components/Core/OpenInvoices/Notice/OpenInvoicesNotice';
import PaymentInstructions from 'components/Core/SidePanel/PaymentInstructions/PaymentInstructions';
import TrustSection from 'components/Core/TrustSection/TrustSection';
import PaymentRequestPreviewCard from 'components/PaymentReq/PreviewCard/PaymentRequestPreviewCard';
import ContactMerchantCard from 'components/Shared/Contact/MerchantCard/ContactMerchantCard';
import MainNpeSurvey from 'components/Shared/QualtricsSurveys/Survey/MaipNpeSurvey';
import UnpayableSurvey from 'components/Shared/QualtricsSurveys/Survey/UnpayableSurvey';
import Separator from 'components/Shared/Separator/Separator';
import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
import deviceInfo from 'server/helpers/deviceInfo';
import { isPaymentRequest } from 'shared/utils';
import { companyInfoSelectors } from 'store/companyInfo/selectors';
import { insightSelectors } from 'store/insight/selectors';
import { downloadPDFDocument, fetchPDFDocument } from 'store/invoiceDocument/slice';
import { modalActions } from 'store/modal/slice';
import { saleSelectors } from 'store/sale/selectors';
import { breakpoints } from 'styles/cp';
import { TXN_MAP } from 'types/constants';

const logger = 'pages/index.page';
const splunkReporter = SplunkReporter.getInstance();
class UnPayable extends Component {
  static async getInitialProps() {
    return {
      pageProps: {},
    };
  }

  componentDidMount() {
    const {
      invoiceDocument: { fetchPDFStatus },
      transactionType,
      token,
      fetchPDFDocument,
    } = this.props;

    if (!(isPaymentRequest(transactionType) || fetchPDFStatus === TXN_MAP.STATUS.SUCCESS)) {
      fetchPDFDocument(token);
    }

    if (typeof window === 'object' && window.navigator) {
      const isTablet = deviceInfo.isTablet(window.navigator.userAgent);
      this.setState({ isTablet });
    }
    splunkReporter.contextual({
      logInfo: { logLevel: 'info', logger },
      event: 'viewSale',
      action: 'render',
      activityInfo: {
        screen: 'index',
      },
    });
  }

  componentDidCatch(error, errorInfo) {
    splunkReporter.contextual({
      logInfo: { logLevel: 'error', logger },
      event: 'viewSale',
      action: 'render',
      activityInfo: {
        screen: 'index',
      },
      error: {
        message: error?.message,
        stack: errorInfo,
      },
    });
  }

  render() {
    const {
      companyEmail,
      companyPhone,
      companyWebAddr,
      companyAddress,
      number,
      dueDate,
      amount,
      balanceAmount,
      currency,
      isPartiallyPaid,
      companyName,
      isFullyPaid,
      isPayable,
      companyLogoUrl,
      token,
      isPayEnabled,
      paymentDetailsMessage,
      transactionType,
      invoiceDocument: { fetchPDFStatus, PDFDocumentBlobUrl },
      config,
      customerOpenInvoices,
      featureFlags,
      sale,
      achOnlineConvenienceFeeAmount,
      achOnlineConvenienceFeeEnabled,
      companyInfo,
      insight,
      showModal,
      ixp,
    } = this.props;
    let pdfUrl;
    let feeAmount;

    if (achOnlineConvenienceFeeEnabled && achOnlineConvenienceFeeAmount) {
      feeAmount = parseFloat(achOnlineConvenienceFeeAmount);
    }
    pdfUrl = PDFDocumentBlobUrl;
    const isPaymentRequestTxn = isPaymentRequest(transactionType);

    let SalePreviewCard;

    if (isPaymentRequestTxn) {
      SalePreviewCard = <PaymentRequestPreviewCard />;
    } else {
      SalePreviewCard = (
        <InvoicePreviewCard
          invoiceDocument={this.props.invoiceDocument}
          companyName={companyName}
          companyLogoUrl={companyLogoUrl}
          number={number}
          dueDate={dueDate}
          amount={amount}
          balanceAmount={balanceAmount}
          currency={currency}
          token={token}
          isPartiallyPaid={isPartiallyPaid}
          isPayable={isPayable}
          isFullyPaid={isFullyPaid}
          showModal={this.props.showModal}
          downloadPDFDocument={this.props.downloadPDFDocument}
          pdfUrl={pdfUrl}
          ixp={ixp}
          featureFlags={featureFlags}
          lazyFetch={false}
          achOnlineConvenienceFeeAmount={feeAmount}
          achOnlineConvenienceFeeEnabled={achOnlineConvenienceFeeEnabled}
        />
      );
    }

    return (
      <Fragment>
        {/*language=SCSS*/}
        <style jsx>{`
          .main {
            width: 65%;
            word-break: break-word;
            @media screen and (max-width: ${breakpoints.md}) {
              padding: 43px 15px 15px 15px;
              max-width: 100%;
              width: 100%;
              height: 90%;
            }

            .truste-w {
              @media screen and (min-width: ${breakpoints.md}) {
                display: none;
              }

              margin: 15px 0;
            }
          }

          .merchant-msg-mobile {
            display: none;
            @media screen and (max-width: ${breakpoints.md}) {
              padding-top: 17px;
              display: block;
            }
          }

          .side-panel {
            width: 320px;
            margin-left: 20px;

            @media screen and (max-width: ${breakpoints.md}) {
              display: none;
            }
          }
        `}</style>

        <UnpayableSurvey
          src={'https://customersurveys.intuit.com/jfe/form/SV_4ZoleQ07JNqQdmu'}
          onHide={() => this.setState({ shouldShowUnpayableSurvey: false })}
        />

        <MainNpeSurvey />

        <div className="main-wrapper flex flex-any">
          <div className="main flex-any">
            <UnpayableCard
              sale={sale}
              companyInfo={companyInfo}
              insight={insight}
              config={config}
              fetchPDFStatus={fetchPDFStatus}
              pdfUrl={pdfUrl}
              showModal={showModal}
              balanceAmount={balanceAmount}
              featureFlags={featureFlags}
              paymentDetailsMessage={paymentDetailsMessage}
              ixp={ixp}
            />
            <div className="merchant-msg-mobile">
              <Separator height={20} />
              {isPayEnabled && <PaymentInstructions />}
              <Separator height={20} />
            </div>
          </div>

          <aside className="side-panel">
            {customerOpenInvoices && (
              <Fragment>
                <OpenInvoicesNotice invoices={customerOpenInvoices} />
                <Separator height={10} />
              </Fragment>
            )}

            {SalePreviewCard}

            <Separator height={15} />

            <ContactMerchantCard
              companyAddress={companyAddress}
              companyEmail={companyEmail}
              companyPhone={companyPhone}
              companyWebAddr={companyWebAddr}
              number={number}
            />

            <Separator height={15} />

            {isPayEnabled && <PaymentInstructions />}

            {paymentDetailsMessage && <Separator height={15} />}

            <TrustSection cdn={config.endpoints.cdn} />
          </aside>
        </div>

        {fetchPDFStatus === TXN_MAP.STATUS.SUCCESS && pdfUrl && (
          <iframe id="hiddenPDFIframe" src={pdfUrl} style={{ display: 'none' }} />
        )}
      </Fragment>
    );
  }
}

function mapStateToProps({
  invoiceDocument,
  sale,
  insight,
  companyInfo,
  config,
  auth,
  ixp,
  featureFlags,
}) {
  return {
    companyEmail: companyInfoSelectors.emailSelector(companyInfo),
    companyPhone: companyInfoSelectors.phoneSelector(companyInfo),
    companyWebAddr: companyInfoSelectors.webAddrSelector(companyInfo),
    companyAddress: companyInfoSelectors.addressSelector(companyInfo),
    companyName: companyInfoSelectors.nameSelector(companyInfo),
    companyLogoUrl: companyInfoSelectors.logoSelector(companyInfo),
    companyLocale: companyInfoSelectors.localeSelector(companyInfo),
    number: saleSelectors.referenceNumberSelector(sale),
    dueDate: saleSelectors.dueDateSelector(sale),
    currency: saleSelectors.currencySelector(sale),
    amount: saleSelectors.amountSelector(sale),
    balanceAmount: saleSelectors.balanceSelector(sale),
    isPartiallyPaid: insightSelectors.isPartiallyPaidSelector(insight),
    description: saleSelectors.descriptionSelector(sale),
    txnDate: saleSelectors.txnDateSelector(sale),
    token: insightSelectors.tokenSelector(insight),
    isPayable: insightSelectors.isPayableSelector(insight),
    isFullyPaid: insightSelectors.isFullyPaidSelector(insight),
    isPayEnabled: insightSelectors.isPayEnabledSelector(insight),
    paymentDetailsMessage: saleSelectors.paymentDetailsMessageSelector(sale),
    transactionType: saleSelectors.typeSelector(sale),
    customerOpenInvoices: saleSelectors.customerOpenInvoicesSelector(sale),
    achOnlineConvenienceFeeAmount: saleSelectors.achOnlineConvenienceFeeAmountSelector(sale),
    achOnlineConvenienceFeeEnabled: saleSelectors.achOnlineConvenienceFeeEnabledSelector(sale),
    invoiceDocument,
    config,
    auth,
    ixp,
    featureFlags,
    sale,
    companyInfo,
    insight,
  };
}

const with_injectIntl = injectIntl(UnPayable);
const with_connectToRedux = connect(mapStateToProps, {
  showModal: modalActions.show,
  fetchPDFDocument,
  downloadPDFDocument,
})(with_injectIntl);

const with_CPStore = CPStore(with_connectToRedux);

export { with_connectToRedux as unpayable };
export default with_CPStore;
